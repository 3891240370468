import {
  AttachmentObjectDescription,
  BiterampObjectDescription,
  ElasticObjectDescription,
  IIPR,
  IJaw,
  IPRObjectDescription,
  IStage,
  ITooth,
  JawType,
  ObjectDescription,
  PonticObjectDescription, ToothObjectDescription
} from '@data/models';
import { Dictionary } from '@types';
import { cloneDeep } from 'lodash';
import { getNameByFile } from './getNameByFile';
import {
  getOcclusionDistancesFromFilename,
} from '@data/providers/SceneDataProvider/utils/getOcclusionDistancesFromFilename';
import {RawFile} from '@data/providers/SceneDataProvider/types';


const fillJawObjects = (
  jaw: IJaw,
  objects: Dictionary<ObjectDescription>,
  jawType: JawType,
  stageId: string,
  fileNames: string[],
  occlusions: RawFile[],
) => {
  const gingivaName = getNameByFile(fileNames[jaw.gingiva.file]);
  const gingivaObject = objects[gingivaName];
  gingivaObject.visible = true;
  gingivaObject.transform = jaw.gingiva.transform;
  gingivaObject.jaw = jawType;

  jaw.teeth.forEach(async (tooth: ITooth) => {
    const toothName = getNameByFile(fileNames[tooth.file]);
    const toothObject = objects[toothName] as ToothObjectDescription;
    toothObject.visible = true;
    toothObject.transform = tooth.transform;
    toothObject.jaw = jawType;
    if (tooth.occlusion) {
      toothObject.occlusionDistances = await getOcclusionDistancesFromFilename(tooth.occlusion, occlusions);
    }

    const rootName = toothName.replace('tooth-', 'root-');
    const rootObject = objects[rootName];

    if (rootObject) {
      rootObject.visible = true;
      rootObject.transform = tooth.transform;
      rootObject.jaw = jawType;
    }
  });

  if (jaw.attachments) {
    jaw.attachments.forEach((attachment: any) => {
      const attachmentName = getNameByFile(fileNames[attachment.file]);
      const attachmentObject = objects[attachmentName];
      attachmentObject.visible = true;
      attachmentObject.transform = attachment.transform;
      attachmentObject.jaw = jawType;
      (attachmentObject as AttachmentObjectDescription).toothId = attachment.number;
    });
  }

  if (jaw.elastics) {
    jaw.elastics.forEach((elastic) => {
      const elasticName = `${elastic.id}--${stageId}`;
      const elasticObject = objects[elasticName];
      elasticObject.visible = true;
      elasticObject.jaw = jawType;
      (elasticObject as ElasticObjectDescription).toothId = elastic.number;
    });
  }

  if (jaw.biteRamps) {
    jaw.biteRamps.forEach((biteRamp: any) => {
      const biterampName = getNameByFile(fileNames[biteRamp.file]);
      const biterampObject = objects[biterampName];
      biterampObject.visible = true;
      biterampObject.transform = biteRamp.transform;
      biterampObject.jaw = jawType;
      (biterampObject as BiterampObjectDescription).toothId = biteRamp.number;
    });
  }

  if (jaw.pontics) {
    jaw.pontics.forEach(async (pontic: any) => {
      const ponticName = getNameByFile(fileNames[pontic.file]);
      const ponticObject = objects[ponticName] as PonticObjectDescription;
      ponticObject.visible = true;
      ponticObject.transform = pontic.transform;
      ponticObject.jaw = jawType;
      ponticObject.jaw = jawType;
      if (pontic.occlusion) {
        ponticObject.occlusionDistances = await getOcclusionDistancesFromFilename(pontic.occlusion, occlusions);
      }
      (ponticObject as PonticObjectDescription).toothId = pontic.number;
    });
  }

  if (jaw.iprs) {
    jaw.iprs
      .forEach((ipr: IIPR, index: number) => {
        if ((ipr.appliedOnStage + 1) <= parseInt(stageId, 10)) {
          const firstToothName = getNameByFile(fileNames[ipr.adjacentTeethFiles[0]]);
          const secondToothName = getNameByFile(fileNames[ipr.adjacentTeethFiles[1]]);

          const iprName = `ipr-${jawType === JawType.Upper ? 'u': 'l'}-${stageId}-${index + 1}`;
          const iprObject = objects[iprName] as IPRObjectDescription;

          iprObject.transform = cloneDeep(objects[firstToothName].transform);
          iprObject.jaw = jawType;
          iprObject.visible = true;
          iprObject.firstToothName = firstToothName;
          iprObject.secondToothName = secondToothName;
        }
      });
  }
};

export const getObjectsForStage = (
  stage: IStage,
  allObjects: Dictionary<ObjectDescription>,
  fileNames: string[],
  occlusions: RawFile[],
): ObjectDescription[] => {
  const stageObjects = cloneDeep(allObjects);

  if (stage.enabled && stage.upper && stage.lower) {
    fillJawObjects(stage.upper, stageObjects, JawType.Upper, stage.id, fileNames, occlusions);
    fillJawObjects(stage.lower, stageObjects, JawType.Lower, stage.id, fileNames, occlusions);
  }

  return Object.values(stageObjects);
};
