import React from 'react';
import styles from './styles.scss';

interface IHeaderMenuProps {
  patient: string | null;
}

const HeaderMenu = ({ patient }: IHeaderMenuProps) => {
  return (
    <div className={styles.headerMenu}>
      {patient && (
        <>
          <div className={styles.headerMenu_label}>{'Patient'}</div>
          <div className={styles.headerMenu_patient}>{patient}</div>
        </>
      )}
    </div>
  );
};

export default HeaderMenu;
