import React, { useEffect, useState } from 'react';
import { Sidebar } from '@view/layouts';
import Photo from './Photo';
import PatientPhotosHeader from './PatientPhotosHeader';
import PatientPhotosFooter from './PatientPhotosFooter';
import ViewportPhoto from './ViewportPhoto';
import { PatientPhotosProps, PatientPhotoType } from '@data/models';
import styles from './styles.scss';

const PatientPhotos = ({
  isOpen,
  onClose,
  photos,
  setIsViewPortPhotoEnabled,
  isViewPortPhotoEnabled,
}: PatientPhotosProps) => {
  const [opacitySliderValue, setOpacitySliderValue] = useState<any>(0.5);
  const initialImagePosition = {
    x: 0,
    y: 0,
  };
  const [position, setPosition] = useState<{ x: number; y: number }>(
    initialImagePosition
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowLeft':
          event.preventDefault();
          setPosition((prevPosition) => ({
            ...prevPosition,
            x: prevPosition.x - 10,
          }));
          break;
        case 'ArrowRight':
          event.preventDefault();
          setPosition((prevPosition) => ({
            ...prevPosition,
            x: prevPosition.x + 10,
          }));
          break;
        case 'ArrowUp':
          event.preventDefault();
          setPosition((prevPosition) => ({
            ...prevPosition,
            y: prevPosition.y - 10,
          }));
          break;
        case 'ArrowDown':
          event.preventDefault();
          setPosition((prevPosition) => ({
            ...prevPosition,
            y: prevPosition.y + 10,
          }));
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // SmilePhoto - default photo
  const [selectedPhoto, setSelectedPhoto] = useState<
    PatientPhotoType | undefined
  >(photos[0]);

  const resetViewportImagePosition = () => {
    setPosition(initialImagePosition);
  };

  const handlePatientPhotosClose = () => {
    onClose();
    setIsViewPortPhotoEnabled(false);
    resetViewportImagePosition();
  };

  const handleRadioChange = () => {
    resetViewportImagePosition();
  };

  const handleOpacityChange = (value: number | number[]) => {
    setOpacitySliderValue(value);
  };

  const opacity = 1 - opacitySliderValue;

  return (
    <>
      {isViewPortPhotoEnabled && selectedPhoto?.url && (
        <ViewportPhoto
          opacity={opacity}
          position={position}
          url={selectedPhoto?.url}
        />
      )}
      <Sidebar size="sm" isOpen={isOpen}>
        <div className={styles.patientPhotos}>
          <PatientPhotosHeader
            onPatientPhotosClose={handlePatientPhotosClose}
            isViewPortPhotoEnabled={isViewPortPhotoEnabled}
            setIsViewPortModeEnabled={setIsViewPortPhotoEnabled}
          />
          <div className={styles.patientPhotos_photos}>
            {photos.map((photo) => {
              return (
                <Photo
                  key={photo.fileKind}
                  fileKind={photo.fileKind}
                  url={photo.url}
                  isViewPortPhotoEnabled={isViewPortPhotoEnabled}
                  selectedPhoto={selectedPhoto}
                  setSelectedPhoto={setSelectedPhoto}
                  onRadioChange={handleRadioChange}
                />
              );
            })}
          </div>
          {isViewPortPhotoEnabled && (
            <PatientPhotosFooter
              opacityValue={opacitySliderValue}
              onOpacityChange={handleOpacityChange}
            />
          )}
        </div>
      </Sidebar>
    </>
  );
};

export default PatientPhotos;
