import { UrlQueryArg } from '@types';
import { FXClient } from '@data/clients';
import moment from 'moment/moment';
import { ITreatmentPlanVersion } from '@data/models';
import { ParsedQuery } from 'query-string';

export interface IParameters {
  clientId: string;
  sessionId: string | null;
  treatmentPlanId: string | null;
  treatmentPlanVersionId: string | null;
  assetUrl: string | null;
}

export interface IData {
  clientId: string;
  patientName: string | null;
  assetUrl: string;
}

class DataProvider {
  constructor() {}

  async getDataByParameters(
    rawParameters: ParsedQuery<string>
  ): Promise<IData | null> {
    const parameters = this._parseParameters(rawParameters);

    // Possible options:
    // 1. sessionId and treatmentPlanId present OR
    // 2. assetUrl presents
    // 3. If (no session_id AND no treatmentPlanId) AND no assetUrl - error, can't load data

    if (
      !parameters.sessionId &&
      !parameters.treatmentPlanId &&
      !parameters.assetUrl
    ) {
      console.error(
        'No needed parameters for load data. Please provide or: 1) session_id, treatment_plan_id, treatment_plan_version_id (optional); 2) webviewer_asset_url.'
      );

      return null;
    }

    let patientName: string | null = null;
    let assetUrl: string | null = null;



    // Try load data by sessionId and treatmentPlanId
    if (parameters.sessionId && parameters.treatmentPlanId) {
      const fxClient = new FXClient();

      try {
        const token = await this._authorize(fxClient, parameters.sessionId);

        if (token) {
          const data = await this._loadData(
            fxClient,
            token,
            parameters.treatmentPlanId,
            parameters.treatmentPlanVersionId,
            parameters.clientId
          );

          if (data) {
            patientName = data.patientName;
            assetUrl = data.assetUrl;
          }
        }
      } finally {
        // Nothing, we need try to get data by assetUrl
      }
    }

    // If no data by sessionId and treatmentPlanId, try to get assetUrl from parameters
    if (!assetUrl) {
      assetUrl = parameters.assetUrl;
    }

    // If no data - error
    if (!assetUrl) {
      console.error('Can\'t load data without working asset url.');

      return null;
    }

    return {
      clientId: parameters.clientId,
      patientName,
      assetUrl
    };
  }

  private _parseParameters(parameters: ParsedQuery<string>): IParameters {
    const getStringOption = (value: UrlQueryArg): string | null =>
      value && typeof value === 'string' ? value : null;

    const result = {
      clientId: getStringOption(parameters.client_id) || 'common',
      sessionId: getStringOption(parameters.session_id),
      treatmentPlanId: getStringOption(parameters.treatment_plan_id),
      treatmentPlanVersionId: getStringOption(
        parameters.treatment_plan_version_id
      ),
      assetUrl: getStringOption(parameters.webviewer_asset_url)
    };

    if (result.assetUrl) {
      result.assetUrl = decodeURI(result.assetUrl);
    }

    if (CONFIG.IS_LOCAL_DATA) {
      result.assetUrl = result.assetUrl || '/assets/demo.zip';
    }

    return result;
  }

  private async _authorize(client: FXClient, sessionId: string) {
    const token = await client.getToken(sessionId);

    if (!token) {
      throw Error('Failed to get a token');
    }

    return token;
  }

  private async _loadData(
    client: FXClient,
    token: string,
    planId: string,
    versionId: string | null,
    clientId: string
  ): Promise<IData | null> {
    const planMetadata = await client.getTreatmentPlanVersions(
      token as string,
      planId
    );

    let planDetails: ITreatmentPlanVersion | null;
    if (versionId) {
      planDetails = await client.getPlanDetails(token, versionId);
    } else {
      const versions = planMetadata.treatmentPlanVersions.slice();
      versions.sort((a, b) => {
        const leftDate = moment(a.createdDate);
        const rightDate = moment(b.createdDate);

        return leftDate.isBefore(rightDate) ? 1 : -1;
      });
      const [latestVersion] = versions;
      planDetails = await client.getPlanDetails(
        token,
        latestVersion.treatmentPlanVersionId
      );
    }

    if (!planDetails) {
      console.error(
        'Can\'t load treatment plan details by version. Please, check treatment_plan_version_id or data by treatment_plan_id.'
      );
      return null;
    }

    return {
      clientId,
      patientName: planMetadata.patientName,
      assetUrl: planDetails.viewerAssetUrl
    };
  }
}

export default DataProvider;
