import {
  LineBasicMaterial,
  Material,
  MeshPhysicalMaterial,
  MeshStandardMaterial,
  SpriteMaterial,
  Texture
} from 'three';
import { Dictionary, Nullable } from '@types';
import {
  IOverlayFeatureConfig,
  IPRObjectDescription,
  MaterialTypes,
  ObjectDescription,
  ObjectTypes
} from '@data/models';
import { ColorConverter, isiOSDevice } from '@utils';
import { TextureLoader } from '@data/loaders';
import { drawRoundRect } from './utils';
import { ConfigProvider } from '@data/providers';
import { uniq } from 'lodash';
import {
  getOcclusionsColorMapTexture,
} from '@data/providers/SceneDataProvider/utils/getOcclusionDistancesFromFilename';


export type TMaterial = Material | Material[] | SpriteMaterial;
const shadersForToothlike = (shader: any) => {
  shader.uniforms.vertexColorMap = {value: getOcclusionsColorMapTexture()};
  shader.vertexShader = shader.vertexShader.replace('varying vec3 vViewPosition;',
    `varying vec3 vViewPosition;
        attribute float distance;
        attribute float hasDistance;
        varying float vDistance;
        varying float vHasDistance;`
  );
  shader.vertexShader = shader.vertexShader.replace('#include <fog_vertex>',
    `#include <fog_vertex>
        vDistance = distance;
        vHasDistance = hasDistance;`);
  shader.fragmentShader = shader.fragmentShader.replace('#define STANDARD',
    `#define STANDARD
        uniform sampler2D vertexColorMap;
        varying float vDistance;
        varying float vHasDistance;`
  );
  shader.fragmentShader = shader.fragmentShader.replace('vec4 diffuseColor = vec4( diffuse, opacity );',
    `vec4 diffuseColor = vec4( diffuse, opacity );
        if (vHasDistance > 0.0) {
          vec4 vertexColor = texture2D(vertexColorMap, vec2(vDistance, 0.0));
          diffuseColor = diffuseColor * vertexColor;
        }`);
};

class MaterialsProvider {
  private readonly _textureLoader: TextureLoader;
  private readonly _envMap: Texture | null;
  private readonly _canvas: HTMLCanvasElement;
  private readonly _canvasContext: CanvasRenderingContext2D | null;
  private readonly _allObjects: Dictionary<ObjectDescription>;
  private _materialsByType: Dictionary<TMaterial>;
  private _iprMaterials: Dictionary<TMaterial>;
  private _configProvider: ConfigProvider;

  constructor (envMap: Texture | null, allObjects: Dictionary<ObjectDescription>, clientId: string) {
    this._textureLoader = new TextureLoader();
    this._envMap = envMap;
    this._canvas = document.createElement('canvas');
    this._canvas.width = 256;
    this._canvas.height = 128;
    this._canvasContext = this._canvas.getContext('2d');
    this._allObjects = allObjects;
    this._materialsByType = {};
    this._iprMaterials = {};
    this._configProvider = new ConfigProvider(clientId);
  }

  async init (): Promise<void> {
    this._materialsByType = {};

    this._materialsByType[MaterialTypes.Attachment] = this._createAttachmentMaterial();
    this._materialsByType[MaterialTypes.Pontic] = this._createPonticMaterial();
    this._materialsByType[MaterialTypes.Elastic] = this._createElasticMaterial();
    this._materialsByType[MaterialTypes.Biteramp] = this._createBiteRampMaterial();
    this._materialsByType[MaterialTypes.Gingiva] = await this._createGingivaMaterial();
    this._materialsByType[MaterialTypes.Tooth] = await this._createToothMaterial();
    this._materialsByType[MaterialTypes.IPRLine] = this._createIPRLineMaterial();
    this._materialsByType[MaterialTypes.Generic] = this._createGenericMaterial();
    this._materialsByType[MaterialTypes.ToothOverlay] = this._createToothOverlayMaterial();
    this._materialsByType[MaterialTypes.ToothRootOverlay] = this._createToothRootOverlayMaterial();

    this._iprMaterials = {};
    const iprObjects = Object
      .values(this._allObjects)
      .filter((obj) => obj.type === ObjectTypes.IPR) as IPRObjectDescription[];

    const iprLabels = uniq(iprObjects.map((ipr: IPRObjectDescription) => ipr.label));
    for (let i = 0; i < iprLabels.length; i++) {
      this._iprMaterials[iprLabels[i]] = await this._createIPRMaterial(iprLabels[i]);
    }
  }

  getMaterial (type: MaterialTypes, iprLabel?: string): TMaterial {
    let result: TMaterial | undefined;
    if (type === MaterialTypes.IPRLabel) {
      if (!iprLabel) {
        throw new Error(`Can't get material for IPR with label ${iprLabel}`);
      }

      result = this._iprMaterials[iprLabel];
    } else {
      result = this._materialsByType[type];
    }

    if (!result) {
      console.error(`Can't find material for ${type} and optional iprLabel ${iprLabel}.`);
      throw new Error(`Can't find material for ${type} and optional iprLabel ${iprLabel}.`);
    }

    return result;
  }

  private _createGenericMaterial (): TMaterial {
    return new MeshStandardMaterial({
      color: 0xeeeeee,
      roughness: 1.0,
    });
  }

  private _createAttachmentMaterial (): TMaterial {
    const config = this._configProvider.getConfig();
    return new MeshStandardMaterial({
      color: ColorConverter.hexStringToNumber(config.materials.attachment.color),
      roughness: 1.0
    });
  }

  private _createBiteRampMaterial (): TMaterial {
    return new MeshStandardMaterial({
      color: 0x5F6389,
      roughness: 1.0
    });
  }

  private _createElasticMaterial (): TMaterial {
    return new MeshStandardMaterial({
      color: 0x191919,
      roughness: 1.0
    });
  }

  private async _createGingivaMaterial (): Promise<TMaterial> {
    const textureFile = isiOSDevice()
      ? '/assets/textures/gingiva_1024.jpg'
      : '/assets/textures/gingiva_2048.jpg';
    const normalMapFile = isiOSDevice()
      ? '/assets/textures/gingiva_normal_1024.jpg'
      : '/assets/textures/gingiva_normal_2048.jpg';
    const texture = await this._textureLoader.load(textureFile) as Texture;
    const normalMap = await this._textureLoader.load(normalMapFile) as Texture;

    return new MeshPhysicalMaterial({
      metalness: 0.0,
      roughness: 0.3,
      reflectivity: 0.5,
      map: texture,
      normalMap: normalMap,
      envMap: this._envMap,
      envMapIntensity: 0.5
    });
  }

  private _createPonticMaterial (): TMaterial {
    const material = new MeshStandardMaterial({
      color: 0xcccccc,
      roughness: 0.5
    });

    material.onBeforeCompile = shadersForToothlike;
    material.needsUpdate = true;

    return material;
  }

  private async _createToothMaterial (): Promise<TMaterial> {
    const TOOTH_COLOR_PRIMARY = 0xdedede;
    const bumpMapFile = isiOSDevice()
      ? '/assets/textures/tooth_bc_1024.jpg'
      : '/assets/textures/tooth_bc_2048.jpg';
    const bumpMap = await this._textureLoader.load(bumpMapFile) as Texture;

    const material = new MeshPhysicalMaterial({
      color: TOOTH_COLOR_PRIMARY,
      metalness: 0.0,
      roughness: 0.3,
      reflectivity: 0.4,
      bumpScale: 0.08,
      // map: texture,
      bumpMap: bumpMap,
      envMap: this._envMap,
      envMapIntensity: 1.0
    });

    material.onBeforeCompile = shadersForToothlike;
    material.needsUpdate = true;
    return material;
  }

  private _createToothOverlayMaterial (): TMaterial {
    const overlayFeature = this._configProvider.getFeatureConfig<IOverlayFeatureConfig>('overlay');

    if (!overlayFeature) {
      throw new Error('No config for feature \'overlay\'.');
    }

    return new MeshStandardMaterial({
      color: ColorConverter.hexStringToNumber(overlayFeature.color),
      opacity: overlayFeature.opacity,
      transparent: true,
      metalness: 0.0,
      roughness: 0.3
    });
  }

  private _createToothRootOverlayMaterial (): TMaterial {
    const overlayFeature = this._configProvider.getFeatureConfig<IOverlayFeatureConfig>('overlay');

    if (!overlayFeature) {
      throw new Error('No config for feature \'overlay\'.');
    }

    return new MeshStandardMaterial({
      color: ColorConverter.hexStringToNumber(overlayFeature.color),
      opacity: overlayFeature.opacity,
      transparent: true,
      metalness: 0.0,
      roughness: 0.3
    });
  }

  private _createIPRLineMaterial (): TMaterial {
    return new LineBasicMaterial({
      color: 0xffffff,
      opacity: 1.0
    });
  }

  private async _createIPRMaterial (label: string): Promise<TMaterial> {
    const labelValue = parseFloat(label);
    label = labelValue ? labelValue.toFixed(1) : '';
    const texture = await this._createSpriteTexture(label);

    return new SpriteMaterial({
      map: texture,
      color: 0xffffff,
      transparent: true
    });
  }

  private async _createSpriteTexture (
    labelText: string,
    fontSize: number = 140,
    borderThickness: number = 8,
    textPadding: number = 30
  ): Promise<Nullable<Texture>> {
    if (!this._canvasContext) {
      return null;
    }

    this._canvasContext.clearRect(0, 0, this._canvas.width, this._canvas.height);

    const backgroundColor = {
      r: 255,
      g: 255,
      b: 255,
      a: 1.0
    };
    const borderColor = {
      r: 255,
      g: 255,
      b: 255,
      a: 1.0
    };

    this._canvasContext.font = `${fontSize}px CeraRoundPro`;
    const metrics = this._canvasContext.measureText(labelText);
    const textWidth = metrics.width + borderThickness * 2 + textPadding;

    this._canvasContext.fillStyle = `rgba(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b}, ${backgroundColor.a})`;
    this._canvasContext.strokeStyle = `rgba(${borderColor.r}, ${borderColor.g}, ${borderColor.b}, ${borderColor.a})`;

    this._canvasContext.lineWidth = borderThickness;
    drawRoundRect(
      this._canvasContext,
      borderThickness,
      0,
      textWidth,
      fontSize * 0.8,
      40,
      true,
      false
    );

    this._canvasContext.fillStyle = 'rgba(0, 0, 0, 1.0)';
    this._canvasContext.fillText(labelText, borderThickness + textPadding, borderThickness + fontSize * 0.9 - textPadding);

    const texture = await this._textureLoader.loadTexture(this._canvas.toDataURL('image/png'));

    if (!texture) {
      return null;
    }

    texture.needsUpdate = true;

    return texture;
  }
}

export default MaterialsProvider;
