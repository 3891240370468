import React from 'react';
import { cn } from '@utils';
import { isMobile } from 'react-device-detect';

type OcclusionsDepthSchemeProps = {
  className?: string;
};

const OcclusionsDepthScheme = ({ className }: OcclusionsDepthSchemeProps) => {
  return (
    <div
      className={cn(
        'absolute top-4 left-1 z-20',
        isMobile && 'top-4 landscape:top-0 left-2',
        className
      )}
    >
      <img
        className={cn(isMobile && 'landscape:w-28')}
        src="/assets/images/occlusion map.png"
        alt="Occlusions depth color scheme"
      />
    </div>
  );
};

export default OcclusionsDepthScheme;
