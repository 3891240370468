import { RawFile } from '../types';
import {CanvasTexture, Color} from 'three';
import {getNameByFile} from '@data/providers/Viewer/utils';

export type ToothOcclusionDistanceType = {[key: string]:number[]};
const MIN_THRESHOLD_IN_MM = 0;
const MAX_THRESHOLD_IN_MM = 1.2;
const COLORING_MIN_THRESHOLD_IN_MM = 0;
const COLORING_MAX_THRESHOLD_IN_MM = 1.2;
const TEX_COORD_FOR_MAX_DISTANCE = 0.7;
const TEX_COORD_FOR_MIN_DISTANCE = 0.3;
const COLORING_PALETTE = ["#FFA000", "#FFFF00", "#00FF00", "#00FFFF", "#00A0ff", "#0000FF"];
const COLORING_NEGATIVE_OUT_OF_RANGE_COLOR = "#FF0000";
const COLORING_POSITIVE_OUT_OF_RANGE_COLOR = "#FFFFFF"/*00*/;
const COLORING_BLUR_DIAMETER_IN_MM = 0.0125;


export const getOcclusionDistancesFromFilename = async (filename: string, occlusionFiles: RawFile[]) => {
  const file = occlusionFiles.find(o => getNameByFile(o.filename) === filename);
  return getOcclusionDistances(file);
}

export const getOcclusionDistances = async (occlusionFile: RawFile | undefined) => {
  const values = [];
  if (occlusionFile) {
    const arrayBuffer = await new Blob([occlusionFile?.data as ArrayBuffer]).arrayBuffer();
    const dataView = new DataView(arrayBuffer);

    const valuesLength = dataView.getInt32(0, true);
    let offset = 4;

    for (let j = 0; j < valuesLength; j++) {
      const value = dataView.getFloat32(offset, true);
      offset += 4;
      values.push(value);
    }
  }
  return distancesToTexCoords(values);
}

export const distancesToTexCoords = (distances: number[]) =>
{
  const distanceTexCoords: number[] = [];

  for (let i = 0; i < distances.length; ++i) {
    distanceTexCoords[i] = distanceToTexCoord(distances[i]);
  }
  return distanceTexCoords;
}

export const getOcclusionsColorMapTexture = () => {
  const textureWidth = 512;
  const canvas = document.createElement('canvas');
  canvas.width = 512;
  canvas.height = 1;
  const ctx = canvas.getContext('2d');
  const gradient = ctx!.createLinearGradient(0, 0, canvas.width, canvas.height);

  for (let x = 0; x < textureWidth; ++x) {
    const texCoord = x / (textureWidth - 1);
    const distance = +texCoordToDistance(texCoord).toFixed(2);
    const color = getColorByDistance(distance);
    gradient.addColorStop(texCoord, `#${color.getHexString()}`);
  }
  ctx!.fillStyle = gradient;
  ctx!.fillRect(0, 0, canvas.width, canvas.height);
  return new CanvasTexture(canvas);
}

const texCoordToDistance = (texCoord: number) =>
{
  const normalizedDistance = lerpInvert(TEX_COORD_FOR_MIN_DISTANCE, TEX_COORD_FOR_MAX_DISTANCE, texCoord);
  return lerpUnclamped(MIN_THRESHOLD_IN_MM, MAX_THRESHOLD_IN_MM, normalizedDistance);
}

const getColorByDistance = (distanceMm: number) =>
{
  const relativeDistanceMm = distanceMm - COLORING_MIN_THRESHOLD_IN_MM;
  const _binWidthMm = (COLORING_MAX_THRESHOLD_IN_MM - COLORING_MIN_THRESHOLD_IN_MM) / COLORING_PALETTE.length;
  const normalizedDistance = (relativeDistanceMm / _binWidthMm) - 0.5;

  const binIndex = Math.floor(normalizedDistance);
  const normalizedBinDistance = normalizedDistance - binIndex;

  const currentColor: Color = getColorByBinIndex(
    binIndex,
    COLORING_NEGATIVE_OUT_OF_RANGE_COLOR,
    COLORING_POSITIVE_OUT_OF_RANGE_COLOR,
    COLORING_PALETTE
  );
  const nextColor: Color = getColorByBinIndex(
    binIndex + 1,
    COLORING_NEGATIVE_OUT_OF_RANGE_COLOR,
    COLORING_POSITIVE_OUT_OF_RANGE_COLOR,
    COLORING_PALETTE
  );
  const blendWeight = getBlendWeight(normalizedBinDistance, COLORING_BLUR_DIAMETER_IN_MM/_binWidthMm);
  return new Color().lerpColors(currentColor, nextColor, blendWeight);
}

const distanceToTexCoord = (distance: number) =>
{
  const normalizedDistance = lerpInvert(MIN_THRESHOLD_IN_MM, MAX_THRESHOLD_IN_MM, distance);
  return clamp01(lerpUnclamped(TEX_COORD_FOR_MIN_DISTANCE, TEX_COORD_FOR_MAX_DISTANCE, normalizedDistance));
}

const clamp01 = (value: number) =>
{
  if (value < 0.0)
  return 0.0;
  return value > 1.0 ? 1.0 : value;
}
const lerpUnclamped = (a: number, b: number, t: number) => a + (b - a) * t;

const lerpInvert = (boundary0: number, boundary1: number, value: number) => {
  return (value - boundary0) / (boundary1 - boundary0);
}

const getBlendWeight = (normalizedBinDistance: number, coloringBlurDiameterInMm: number) => {
  return clamp(
    (normalizedBinDistance - 0.5) / coloringBlurDiameterInMm + 0.5,
    0, 1);
}

const clamp = (f: number, low: number, high: number) => {
  return (f < low) ? low : (f > high) ? high : f;
}

const getColorByBinIndex = (binIndex: number, coloringNegativeOutOfRangeColor: string,
  coloringPositiveOutOfRangeColor: string, coloringPalette: string[]) => {
  if (binIndex < 0) return new Color(coloringNegativeOutOfRangeColor);
  if (binIndex >= coloringPalette.length) return new Color(coloringPositiveOutOfRangeColor);
  return new Color(coloringPalette[binIndex]);
}