import React from 'react';
import styles from './styles.scss';

type ViewportPhotoProps = {
  opacity: number;
  position: {
    x: number;
    y: number;
  };
  url?: string;
};

const ViewportPhoto = ({ opacity, url, position }: ViewportPhotoProps) => {
  return (
    <div
      style={{
        opacity,
        left: position.x,
        top: position.y,
      }}
      className={styles.viewportPhotoWrapper}
    >
      <img
        className={styles.viewportPhotoWrapper_img}
        src={url}
        alt="patient photo"
      />
    </div>
  );
};

export default ViewportPhoto;
