import {
  AttachmentObjectDescription,
  BiterampObjectDescription,
  ElasticObjectDescription,
  GridObjectDescription,
  ISceneModifiers,
  IStage,
  ITooth,
  ITreatmentPlan,
  IViewSettings,
  JawType,
  ObjectDescription,
  ObjectTypes,
  PonticObjectDescription,
  SceneDescription,
  TeethMovementData,
  TeethMovementStage,
  ToothObjectDescription,
} from '@data/models';
import { RawFile, TreatmentPlan } from './types';
import { BlobReader, BlobWriter, TextWriter, ZipReader } from '@zip.js/zip.js';
import { FXClient } from '@data/clients';
import { Matrix4 } from 'three';
import {
  getAllObjects,
  getFilledStages,
  getObjectsForStage,
  getStagesCount,
} from './utils';
import { cloneDeep } from 'lodash';
import {
  defaultLightsDescription,
  defaultPerspectiveCameraDescription,
  defaultRendererDescription,
} from './constants';
import { Dictionary } from '@types';
import { Maybe } from '@utils';
import { ConfigProvider } from '@data/providers';
import {
  getJawDisplacementOcclusion,
  getJawDisplacementOcclusions,
  getJawDisplacementTransform,
  JawDisplacementOcclusion,
} from '@data/providers/SceneDataProvider/utils/getJawDisplacementOcclusions';
import { DentalNotationType } from '@data/providers/DentalNotationProvider/Models';

export interface ISceneData {
  allObjects: Dictionary<ObjectDescription>;
  objectsByStage: ObjectDescription[][];
  upperStagesCount: number;
  lowerStagesCount: number;
  upperStageOvercorrection: number | null;
  lowerStageOvercorrection: number | null;
  allStagesCount: number;
  isJawDisplacementPresent: boolean;
  firstStage: number;
  teethMovement: TeethMovementData | null;
  occlusions: RawFile[];
  toothNumbering: DentalNotationType;
}

class SceneDataProvider {
  private readonly _configProvider: ConfigProvider;
  private readonly _fxClient: FXClient;
  private _jawDisplacementTransform: number[] | null = null;
  private _jawDisplacementOcclusions: JawDisplacementOcclusion[] | null = null;

  constructor(clientId: string) {
    this._fxClient = new FXClient();
    this._configProvider = new ConfigProvider(clientId);
  }

  async loadSceneDataByUrl(assetUrl: string): Promise<Maybe<ISceneData>> {
    const assets = await this._unzipAssets(assetUrl);
    if (!assets.isPresent()) {
      return Maybe.error<ISceneData>(assets.error);
    }

    const { plan, files, occlusions } = assets.value;

    this._jawDisplacementTransform = getJawDisplacementTransform(plan);
    this._jawDisplacementOcclusions = await getJawDisplacementOcclusions(
      occlusions,
      plan
    );

    const { upperStagesCount, lowerStagesCount } = getStagesCount(plan);
    const stages = getFilledStages(plan);
    const allObjects = getAllObjects(plan, files, stages, this._configProvider);

    const objectsByStage: ObjectDescription[][] = await Promise.all(
      stages.map(async (stage: IStage) => {
        return getObjectsForStage(stage, allObjects, plan.files, occlusions);
      })
    );

    // Fill overlay teeth transform data
    const firstStageTeeth = objectsByStage[0].filter(
      (obj) => obj.type === ObjectTypes.Tooth
    );

    objectsByStage.forEach((stageObjs) => {
      stageObjs.forEach((desc) => {
        if (desc.type === ObjectTypes.ToothOverlay) {
          const id = desc.id.replace('overlay-', '');
          const toothDesc = firstStageTeeth.find((t) => t.id === id);
          if (!toothDesc) {
            throw new Error(
              `Can't find tooth with id ${id} for related overlay tooth with id ${desc.id}`
            );
          }
          desc.transform = toothDesc.transform || null;
        }
        if (desc.type === ObjectTypes.ToothRootOverlay) {
          const id = desc.id.replace('overlay-root', 'tooth');
          const toothDesc = firstStageTeeth.find((t) => t.id === id);
          if (!toothDesc) {
            throw new Error(`Can't find tooth with id ${id} for related overlay root with id ${desc.id}`);
          }
          desc.transform = toothDesc.transform || null;
        }
      });
    });

    let lowerStageOvercorrection: number | null = null;
    let upperStageOvercorrection: number | null = null;

    stages.forEach((stage: IStage, index: number) => {
      if (stage.enabled && stage.upper && stage.lower) {
        if (
          stage.lower.hasOverCorrection &&
          lowerStageOvercorrection === null
        ) {
          lowerStageOvercorrection = index;
        }

        if (
          stage.upper.hasOverCorrection &&
          upperStageOvercorrection === null
        ) {
          upperStageOvercorrection = index;
        }
      }
    });

    const movementData = this._getTeethMovementData(stages);

    return Maybe.some<ISceneData>({
      allObjects,
      objectsByStage,
      upperStagesCount,
      lowerStagesCount,
      upperStageOvercorrection,
      lowerStageOvercorrection,
      allStagesCount: stages.length,
      isJawDisplacementPresent: this._jawDisplacementTransform !== null,
      firstStage: parseInt(plan.stages[0].id),
      teethMovement: movementData,
      occlusions: occlusions,
      toothNumbering:
        DentalNotationType[
          plan.toothNumbering as keyof typeof DentalNotationType
        ],
    });
  }

  getSceneDescription(): SceneDescription {
    return {
      name: 'Viewer',
      background: 0x6e7980,
      // background: 0xaaaaaa,
      width: 800,
      height: 600,
      position: { x: 0, y: 0, z: 0 },
      renderer: cloneDeep(defaultRendererDescription),
      camera: cloneDeep(defaultPerspectiveCameraDescription),
      orbitControl: {
        azimuthAngle: 0,
        polarAngle: 0,
        target: { x: 0, y: 0, z: 0 },
      },
      lights: cloneDeep(defaultLightsDescription),
      objects: [],
    };
  }

  applyViewSettingsForObjects(
    objects: ObjectDescription[],
    viewSettings: IViewSettings
  ): ObjectDescription[] {
    const updatedObjects = objects.map((item: ObjectDescription) => {
      const newObject = cloneDeep(item);

      // If object is invisible, we don't need to do anything
      if (!newObject.visible) {
        return newObject;
      }

      if (newObject.type === ObjectTypes.Grid) {
        const gridObject = newObject as GridObjectDescription;
        gridObject.visible =
          viewSettings.gridSize === gridObject.size ? viewSettings.grid : false;
        return gridObject;
      }

      // If jaw is invisible, make all jaw objects invisible and return
      if (!viewSettings.upperJawVisible && newObject.jaw === JawType.Upper) {
        newObject.visible = false;
        return newObject;
      }
      if (!viewSettings.lowerJawVisible && newObject.jaw === JawType.Lower) {
        newObject.visible = false;
        return newObject;
      }

      // If jaw is visible, apply view settings to the object
      switch (newObject.type) {
        case ObjectTypes.Attachment:
          newObject.visible = viewSettings.attachments;
          break;

        case ObjectTypes.Elastic:
          newObject.visible = viewSettings.elastics;
          break;

        case ObjectTypes.Biteramp:
          newObject.visible = viewSettings.biteRamps;
          break;

        case ObjectTypes.IPR:
          newObject.visible = viewSettings.iprs;
          break;

        case ObjectTypes.Pontic:
          newObject.visible = viewSettings.pontics;
          (newObject as PonticObjectDescription).occlusionVisible =
            viewSettings.occlusions;
          break;

        case ObjectTypes.ToothOverlay:
          newObject.visible = viewSettings.overlay;
          break;

        case ObjectTypes.ToothRootOverlay:
          newObject.visible = viewSettings.hideGum && viewSettings.overlay;
          break;

        case ObjectTypes.ToothRoot:
          newObject.visible = viewSettings.hideGum;
          break;

        case ObjectTypes.Gingiva:
          newObject.visible = !viewSettings.hideGum;
          break;

        case ObjectTypes.Tooth:
          (newObject as ToothObjectDescription).occlusionVisible =
            viewSettings.occlusions;
          break;

        default:
          // For not presented types above we don't need to do anything.
          break;
      }

      return newObject;
    });

    // If pontics are not display, we need to hide related objects with the same tooth ID
    if (!viewSettings.pontics) {
      const ponticToothIds = updatedObjects
        .filter((obj) => obj.type === ObjectTypes.Pontic)
        .map((obj) => (obj as PonticObjectDescription).toothId);

      updatedObjects.forEach((obj) => {
        if (obj.type === ObjectTypes.Attachment) {
          const attachmentObj = obj as AttachmentObjectDescription;
          if (ponticToothIds.includes(attachmentObj.toothId)) {
            attachmentObj.visible = false;
          }
        }

        if (obj.type === ObjectTypes.Biteramp) {
          const biterampObj = obj as BiterampObjectDescription;
          if (ponticToothIds.includes(biterampObj.toothId)) {
            biterampObj.visible = false;
          }
        }

        if (obj.type === ObjectTypes.Elastic) {
          const elasticObj = obj as ElasticObjectDescription;
          if (ponticToothIds.includes(elasticObj.toothId)) {
            elasticObj.visible = false;
          }
        }
      });
    }

    return updatedObjects;
  }

  applySceneModifiers(
    objects: ObjectDescription[],
    sceneModifiers: ISceneModifiers
  ): ObjectDescription[] {
    return objects.map((item) => {
      const newItem = cloneDeep(item);
      if (
        sceneModifiers.jawDisplacement &&
        sceneModifiers.isJawDisplacementSelected &&
        this._jawDisplacementTransform
      ) {
        if (newItem.jaw === JawType.Lower) {
          const displacementMatrix = new Matrix4();
          displacementMatrix.fromArray(this._jawDisplacementTransform, 0);

          switch (newItem.type) {
            case ObjectTypes.Gingiva:
            case ObjectTypes.Elastic:
              newItem.transform = displacementMatrix.toArray();
              break;
            default:
              if (newItem.transform) {
                const initialTransformMatrix = new Matrix4();
                initialTransformMatrix.fromArray(newItem.transform, 0);
                initialTransformMatrix.multiply(displacementMatrix);
                newItem.transform = initialTransformMatrix.toArray();
              }
              break;
          }
        }
        if (
          newItem.type === ObjectTypes.Tooth ||
          newItem.type === ObjectTypes.Pontic
        ) {
          // @ts-ignore
          newItem.occlusionDistances = getJawDisplacementOcclusion(
            this._jawDisplacementOcclusions,
            newItem.id
          );
        }
      }

      return newItem;
    });
  }

  private async _unzipAssets(assetUrl: string): Promise<Maybe<TreatmentPlan>> {
    const result = await this._fxClient.getViewerAsset(assetUrl);
    if (!result.isPresent()) {
      return Maybe.error<TreatmentPlan>(result.error);
    }

    const zipFileReader = new BlobReader(result.value);
    const zipReader = new ZipReader(zipFileReader);

    const entries = await zipReader.getEntries();

    const data: (RawFile | null)[] = await Promise.all(
      entries.map(async (entry): Promise<RawFile | null> => {
        if (!entry || !entry.getData || entry.directory) {
          return null;
        }

        const writer =
          entry.filename === 'meta.json' ? new TextWriter() : new BlobWriter();
        const data = await entry.getData(writer);

        return {
          filename: entry.filename,
          data: data,
        };
      })
    );

    await zipReader.close();

    const [rawPlan] = data.filter(
      (item) => item && item.filename === 'meta.json'
    );

    if (!rawPlan) {
      throw Error('Can\'t find meta.json!');
    }

    const plan: ITreatmentPlan = JSON.parse(rawPlan.data as string);

    return Maybe.some<TreatmentPlan>({
      plan,
      files: data
        .filter((item) => item !== null)
        .filter(
          (item: RawFile | null) =>
            item &&
            item.filename !== 'meta.json' &&
            !item.filename.includes('occlusion')
        ) as RawFile[],
      occlusions: data
        .filter((item) => item !== null)
        .filter(
          (item) => item && item.filename.includes('occlusion')
        ) as RawFile[],
    });
  }

  private _getTeethMovementData(stages: IStage[]): TeethMovementData | null {
    const getToothId = (id: string) => id.replace('tooth-', '');
    const getMovementData = (teeth: ITooth[]) =>
      teeth.reduce((r: TeethMovementStage, tooth: ITooth) => {
        const id = getToothId(tooth.id);
        if (tooth.displacement) {
          r[id] = tooth.displacement;
        }
        return r;
      }, {});

    const result = stages.reduce((res: TeethMovementData, stage: IStage) => {
      const lowerTeeth = stage.lower && getMovementData(stage.lower.teeth);
      const upperTeeth = stage.upper && getMovementData(stage.upper.teeth);

      if (
        lowerTeeth &&
        Object.keys(lowerTeeth).length > 0 &&
        upperTeeth &&
        Object.keys(upperTeeth).length > 0
      ) {
        res[stage.id] = {
          ...upperTeeth,
          ...lowerTeeth,
        };
      }

      return res;
    }, {});

    return Object.keys(result).length > 0 ? result : null;
  }
}

export default SceneDataProvider;
