import React from 'react';
import { cloneDeep, debounce } from 'lodash';
import styles from './styles.scss';
import { WebGLScene } from './WebGLScene/WebGLScene';
import {
  CameraDescription,
  GizmoViewOrientation,
  ISceneModifiers,
  IViewSettings,
  ObjectDescription,
  OrbitControlDescription,
  SceneDescription,
  TeethMovementData,
} from '@data/models';
import { Dictionary, Nullable } from '@types';
import { SceneDataProvider } from '@data/providers';
import { ErrorCover, Knob, LoadingCover, SceneGizmo } from '@view/components';
import { KeyID } from '@data/constants';
import { ErrorType, Maybe } from '@utils';
import { RawFile } from '@data/providers/SceneDataProvider/types';
import { ISceneData } from '@data/providers/SceneDataProvider';
import { DentalNotationType } from '@data/providers/DentalNotationProvider/Models';

type Dimensions = {
  width: number;
  height: number;
};

const WIDTH_THRESHOLD = 950;
const ANIMATION_FRAME_DURATION_MS = 200;
const RESIZE_DEBOUNCE_MS = 200;

const getKnobSizeByWidth = (width: number) => {
  if (width >= WIDTH_THRESHOLD) {
    return 300;
  }

  return 200;
};

interface ISceneProps {
  clientId: string;
  viewSettings: IViewSettings;
  assetUrl: string;
  gizmoViewOrientationChanged: (side: GizmoViewOrientation) => void;
  setSceneData: (sceneData: ISceneData) => void;
  isViewPortPhotoEnabled: boolean;
  renderTeethMovementTable: (data: any) => React.ReactNode;
}

interface ISceneState {
  stage: number;
  objectsByStage: ObjectDescription[][];
  isPlayingAnimation: boolean;
  isLoading: boolean;
  upperStagesCount: number;
  lowerStagesCount: number;
  knobSize: number;
  upperStageOvercorrection: number | null;
  lowerStageOvercorrection: number | null;
  allStagesCount: number;
  sceneModifiers: ISceneModifiers;
  isJawDisplacementPresent: boolean;
  errorType: ErrorType;
  firstStage: number;
  teethMovement: TeethMovementData | null;
  occlusions: RawFile[];
  toothNumbering: DentalNotationType;
}

class SceneWidget extends React.Component<ISceneProps, ISceneState> {
  private _sceneDescription: SceneDescription;
  private readonly _sceneDataProvider: SceneDataProvider;
  private readonly _containerRef: React.RefObject<HTMLDivElement>;
  private readonly _canvasRef: React.RefObject<HTMLCanvasElement>;
  private _scene: WebGLScene | undefined;
  private _animationTimer: number | null = null;

  private readonly onResizeDebounced: () => void;

  constructor(props: ISceneProps) {
    super(props);

    this.state = {
      stage: 0,
      objectsByStage: [],
      isPlayingAnimation: false,
      isLoading: true,
      upperStagesCount: 0,
      lowerStagesCount: 0,
      upperStageOvercorrection: null,
      lowerStageOvercorrection: null,
      knobSize: getKnobSizeByWidth(window.innerWidth),
      allStagesCount: 0,
      sceneModifiers: {
        jawDisplacement: false,
        isJawDisplacementSelected: false,
      },
      isJawDisplacementPresent: false,
      errorType: ErrorType.None,
      firstStage: 0,
      teethMovement: null,
    };

    this._containerRef = React.createRef<HTMLDivElement>();
    this._canvasRef = React.createRef<HTMLCanvasElement>();

    this.onResize = this.onResize.bind(this);
    this.onResizeDebounced = debounce(this.onResize, RESIZE_DEBOUNCE_MS);

    this._sceneDataProvider = new SceneDataProvider(props.clientId);
    this._sceneDescription = this._sceneDataProvider.getSceneDescription();
  }

  async componentDidMount() {
    if (!this._canvasRef.current || !this._containerRef.current) {
      throw new Error('Can\'t find container and canvas. Can\'t render scene.');
    }

    window.addEventListener('resize', this.onResizeDebounced);
    window.addEventListener('keydown', this.onKeyboardKeyDown);

    try {
      const allObjects = await this.loadData();

      if (allObjects.isPresent()) {
        this._scene = new WebGLScene(
          this._sceneDescription,
          this._canvasRef.current,
          this.onCameraChange,
          this.onControlChange,
          allObjects.value || {},
          this.props.clientId
        );

        await this._scene.initialize();

        this._scene.animate();

        this.onResize();

        const stats = this._scene.getStats();
        if (stats) {
          this._containerRef.current.appendChild(stats.dom);
        }

        this.setState(() => ({ isLoading: false }));

        await this.renderStage();
      }
    } catch (error) {
      this.setState(() => ({
        errorType: ErrorType.Unexpected,
      }));
    }
  }

  async componentDidUpdate(prevProps: Readonly<ISceneProps>) {
    const { isLoading, isPlayingAnimation } = this.state;

    if (isLoading) {
      return;
    }

    await this.renderStage();

    if (isPlayingAnimation) {
      // Here we can see incorrect type error, because TS expects nodejs Timeout type, but in browser
      // setTimeout returns number. So, we can ignore this error, because we can't do anything with it.
      // @ts-ignore
      this._animationTimer = setTimeout(() => {
        this.setState((state) => {
          let newStage = state.stage + 1;
          let isPlayingAnimation = true;
          if (newStage >= state.objectsByStage.length) {
            newStage = state.objectsByStage.length - 1;
            isPlayingAnimation = false;
          }
          return {
            stage: newStage,
            isPlayingAnimation,
            sceneModifiers: {
              ...state.sceneModifiers,
              jawDisplacement: newStage >= state.allStagesCount - 1,
            },
          };
        });
      }, ANIMATION_FRAME_DURATION_MS);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResizeDebounced);
    window.removeEventListener('keydown', this.onKeyboardKeyDown);
  }

  async loadData(): Promise<Maybe<Dictionary<ObjectDescription>>> {
    const { assetUrl } = this.props;
    const sceneData = await this._sceneDataProvider.loadSceneDataByUrl(
      assetUrl
    );
    if (!sceneData.isPresent()) {
      this.setState(() => ({
        errorType: sceneData.error.type,
      }));
      return Maybe.error<Dictionary<ObjectDescription>>(sceneData.error);
    }

    this.props.setSceneData(sceneData.value);

    const {
      allObjects,
      objectsByStage,
      upperStagesCount,
      lowerStagesCount,
      upperStageOvercorrection,
      lowerStageOvercorrection,
      allStagesCount,
      isJawDisplacementPresent,
      firstStage,
      teethMovement,
      occlusions,
      toothNumbering,
    } = sceneData.value;

    this.setState((state) => ({
      objectsByStage,
      upperStagesCount,
      lowerStagesCount,
      upperStageOvercorrection,
      lowerStageOvercorrection,
      allStagesCount,
      stage: allStagesCount - 1,
      sceneModifiers: {
        ...state.sceneModifiers,
        jawDisplacement: true,
      },
      isJawDisplacementPresent,
      firstStage,
      teethMovement,
      occlusions,
      toothNumbering,
    }));

    return Maybe.some<Dictionary<ObjectDescription>>(allObjects);
  }

  onResize() {
    const dimensions = this.getContainerDimensions();
    if (!dimensions) {
      return;
    }

    this._scene?.update(this._sceneDescription);

    this._scene?.resize(dimensions.width, dimensions.height);
    this._scene?.render();

    this.setState(() => ({
      knobSize: getKnobSizeByWidth(window.innerWidth),
    }));
  }

  onKeyboardKeyDown = (e: KeyboardEvent) => {
    if (!this.props.isViewPortPhotoEnabled) {
      if (e.key === KeyID.ARROW_LEFT) {
        this.showPrevStage(e);
      } else if (e.key === KeyID.ARROW_RIGHT) {
        this.showNextStage(e);
      }
    }
  };

  onCameraChange = (description: CameraDescription) => {
    this._sceneDescription.camera = cloneDeep(description);
  };

  onControlChange = (description: OrbitControlDescription) => {
    this._sceneDescription.orbitControl = cloneDeep(description);
  };

  onStageChange = (value: number) => {
    this.setState((state) => {
      const newValue = Math.min(
        Math.max(value, state.firstStage),
        state.allStagesCount - 1
      );

      const jawDisplacement: boolean = newValue >= state.allStagesCount - 1;

      return {
        stage: newValue,
        sceneModifiers: {
          ...state.sceneModifiers,
          jawDisplacement,
        },
      };
    });
  };

  async renderStage() {
    const { viewSettings } = this.props;
    const { stage, objectsByStage, isLoading, sceneModifiers } = this.state;
    if (objectsByStage.length === 0 || isLoading) {
      return;
    }

    const objects = this._sceneDataProvider.applyViewSettingsForObjects(
      objectsByStage[stage],
      viewSettings
    );
    this._sceneDescription.objects =
      this._sceneDataProvider.applySceneModifiers(objects, sceneModifiers);
    await this._scene?.update(this._sceneDescription);
  }

  startAnimation = (e?: React.MouseEvent) => {
    e?.stopPropagation();

    this.setState((state) => {
      const newStage =
        state.stage >= state.objectsByStage.length - 1
          ? state.firstStage
          : state.stage;

      return {
        stage: newStage,
        isPlayingAnimation: true,
        sceneModifiers: {
          ...state.sceneModifiers,
          jawDisplacement: newStage >= state.allStagesCount - 1,
        },
      };
    });
  };

  pauseAnimation = (e?: React.MouseEvent) => {
    e?.stopPropagation();

    if (this._animationTimer) {
      clearTimeout(this._animationTimer);
      this._animationTimer = null;
    }

    this.setState(() => ({ isPlayingAnimation: false }));
  };

  showPrevStage = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.pauseAnimation();
    this.setState((state) => {
      let newStage = state.stage - 1;
      if (newStage < state.firstStage) {
        newStage = state.firstStage;
      }

      const jawDisplacement: boolean = newStage >= state.allStagesCount - 1;

      return {
        stage: newStage,
        sceneModifiers: {
          ...state.sceneModifiers,
          jawDisplacement,
        },
      };
    });
  };

  showNextStage = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.pauseAnimation();
    this.setState((state) => {
      let newStage = state.stage + 1;
      if (newStage >= state.objectsByStage.length) {
        newStage = state.objectsByStage.length - 1;
      }

      const jawDisplacement: boolean = newStage >= state.allStagesCount - 1;

      return {
        stage: newStage,
        sceneModifiers: {
          ...state.sceneModifiers,
          jawDisplacement,
        },
      };
    });
  };

  getContainerDimensions(): Nullable<Dimensions> {
    if (this._containerRef.current) {
      const width = this._containerRef.current.offsetWidth;
      const height = this._containerRef.current.offsetHeight;

      return { width, height };
    }

    return null;
  }

  showTop = () => {
    this._sceneDescription.orbitControl.azimuthAngle = 0.0;
    this._sceneDescription.orbitControl.polarAngle = Math.PI / 2.0;
    this._sceneDescription.orbitControl.target = { x: 0, y: 0, z: 0 };

    this._scene?.update(this._sceneDescription);

    this.props.gizmoViewOrientationChanged?.(GizmoViewOrientation.Bottom);
  };

  showFront = () => {
    this._sceneDescription.orbitControl.azimuthAngle = 0.0;
    this._sceneDescription.orbitControl.polarAngle = 0.0;
    this._sceneDescription.orbitControl.target = { x: 0, y: 0, z: 0 };

    this._scene?.update(this._sceneDescription);

    this.props.gizmoViewOrientationChanged?.(GizmoViewOrientation.Front);
  };

  showLeft = () => {
    this._sceneDescription.orbitControl.azimuthAngle = -Math.PI / 2.0;
    this._sceneDescription.orbitControl.polarAngle = 0.0;
    this._sceneDescription.orbitControl.target = { x: 0, y: 0, z: 0 };

    this._scene?.update(this._sceneDescription);

    this.props.gizmoViewOrientationChanged?.(GizmoViewOrientation.Left);
  };

  showRight = () => {
    this._sceneDescription.orbitControl.azimuthAngle = Math.PI / 2.0;
    this._sceneDescription.orbitControl.polarAngle = 0.0;
    this._sceneDescription.orbitControl.target = { x: 0, y: 0, z: 0 };

    this._scene?.update(this._sceneDescription);

    this.props.gizmoViewOrientationChanged?.(GizmoViewOrientation.Right);
  };

  showBottom = () => {
    this._sceneDescription.orbitControl.azimuthAngle = 0;
    this._sceneDescription.orbitControl.polarAngle = -Math.PI / 2.0;
    this._sceneDescription.orbitControl.target = { x: 0, y: 0, z: 0 };

    this._scene?.update(this._sceneDescription);

    this.props.gizmoViewOrientationChanged?.(GizmoViewOrientation.Top);
  };

  showStartStage = () => {
    this.pauseAnimation();

    this.setState((state) => ({
      stage: state.firstStage,
      sceneModifiers: {
        jawDisplacement: false,
        isJawDisplacementSelected:
          state.sceneModifiers.isJawDisplacementSelected,
      },
    }));
  };

  showFinalStage = () => {
    this.pauseAnimation();

    this.setState((state) => ({
      stage: state.objectsByStage.length - 1,
      sceneModifiers: {
        jawDisplacement: true,
        isJawDisplacementSelected:
          state.sceneModifiers.isJawDisplacementSelected,
      },
    }));
  };

  toggleJawDisplacement = () => {
    this.setState((state) => ({
      sceneModifiers: {
        ...state.sceneModifiers,
        isJawDisplacementSelected:
          !state.sceneModifiers.isJawDisplacementSelected,
      },
    }));
  };

  getErrorMessage(
    errorType: ErrorType
  ): string | React.JSX.Element | undefined {
    switch (errorType) {
      case ErrorType.Unexpected:
        return 'Oops, something went wrong. Please contact your doctor.';
      case ErrorType.NotFound:
        return 'No treatment plan was found using this link. Please contact your doctor to obtain a new one.';
      case ErrorType.ForbiddenAccess:
        return 'The link has expired. Please contact your doctor to obtain a new one.';
      case ErrorType.None:
      default:
        return undefined;
    }
  }

  render() {
    const {
      stage,
      objectsByStage,
      isPlayingAnimation,
      isLoading,
      upperStagesCount,
      lowerStagesCount,
      knobSize,
      upperStageOvercorrection,
      lowerStageOvercorrection,
      allStagesCount,
      sceneModifiers,
      isJawDisplacementPresent,
      errorType,
      firstStage,
      toothNumbering,
      teethMovement,
    } = this.state;

    return (
      <div className={styles.sceneWidget}>
        <div className={styles.sceneWidget_content} ref={this._containerRef}>
          <canvas ref={this._canvasRef} />
          <div className={styles.sceneWidget_content_iprs} id="iprs" />
        </div>

        <div className={styles.sceneWidget_gizmo}>
          <SceneGizmo
            onShowTop={this.showBottom}
            onShowLeft={this.showLeft}
            onShowRight={this.showRight}
            onShowFront={this.showFront}
            onShowBottom={this.showTop}
          />
        </div>

        <div className={styles.sceneWidget_knob}>
          {objectsByStage.length > 0 && (
            <Knob
              size={knobSize}
              stagesAll={allStagesCount}
              stagesUpper={upperStagesCount}
              stagesLower={lowerStagesCount}
              stageCurrent={stage}
              onStageChange={this.onStageChange}
              onAnimationBackwardClick={this.showPrevStage}
              onAnimationForwardClick={this.showNextStage}
              onAnimationStartClick={this.startAnimation}
              onAnimationStopClick={this.pauseAnimation}
              onShowStartStageClick={this.showStartStage}
              onShowFinalStageClick={this.showFinalStage}
              onJawDisplacementClick={this.toggleJawDisplacement}
              isJawDisplacementActive={
                sceneModifiers.isJawDisplacementSelected &&
                sceneModifiers.jawDisplacement
              }
              isJawDisplacementPresent={isJawDisplacementPresent}
              upperStageOvercorrection={upperStageOvercorrection}
              lowerStageOvercorrection={lowerStageOvercorrection}
              isPlaying={isPlayingAnimation}
              firstStage={firstStage}
            />
          )}
        </div>
        {this.props.renderTeethMovementTable({
          stage,
          toothNumbering,
          teethMovement,
        })}
        <LoadingCover isLoading={isLoading} message={'Loading scene...'} />
        <ErrorCover error={this.getErrorMessage(errorType)} />
      </div>
    );
  }
}

export default SceneWidget;
