import { IConfig, IFeatureConfig } from '@data/models';
import { cloneDeep, merge } from 'lodash';
import commonConfig from '@config/common.json';
import candidConfig from '@config/candid.json';
import dndConfig from '@config/dnd.json';
import gpDfaConfig from '@config/gp_dfa.json';

class ConfigProvider {
  private _clientId: string;
  private _config: IConfig;

  constructor (clientId: string) {
    this._clientId = clientId;
    this._config = ConfigProvider.getConfigForClient(clientId);
  }

  static getConfigForClient (clientId: string): IConfig {
    const result = cloneDeep(commonConfig);

    switch (clientId.toLowerCase()) {
      case 'cnd':
        return merge(result, candidConfig) as IConfig;

      case 'dnd':
        return merge(result, dndConfig) as IConfig;

      case 'gp_dfa':
        return merge(result, gpDfaConfig) as IConfig;

      default:
        return commonConfig as IConfig;
    }
  }

  getConfig (): IConfig {
    return this._config;
  }

  isFeatureEnabled (featureName: string): boolean {
    const feature = this._config.features[featureName];

    if (!feature) {
      return false;
    }

    return feature.enabled;
  }

  getFeatureConfig<T> (featureName: string): T | null {
    const feature = this._config.features[featureName];

    return (feature as T) || null;
  }
}

export default ConfigProvider;
